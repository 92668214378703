import { getLocale } from '@helpers/env'
import {
  basicMenuItemFields,
  extendedMenuItemFields,
  badgeFields,
  flashBannerNew,
  imageFields
} from '../fragments/header'

export const headerDesktopMenuQuery = `query {
  menu: headerCollection(where: {title_contains: "Menu"}, limit: 1, locale: "${getLocale()}") {
    items {
      desktop {
        primaryNavigation: mainCollection(limit: 7) {
          items {
            ...${extendedMenuItemFields}
          }
        }
        secondaryNavigation: additionalCollection(limit: 5) {
          items {
            ...${basicMenuItemFields}
          }
        }
      }
    }
  }
}`

export const headerFlashBannerQuery = `query {
  menu: headerCollection(where: {title_contains: "Menu"}, limit: 1, locale: "${getLocale()}") {
    items {
      ${flashBannerNew}
    }
  }
}`

export const headerMobileMenuQuery = `query {
  menu: headerCollection(where: {title_contains: "Menu"}, limit: 1, locale: "${getLocale()}") {
    items {
      mobile {
        primaryNavigation: mainCollection(limit: 10) {
          items {
            title
            byline
            accordion
            highlight: secondary
            url
            textColour
            iconName
            classes
            handles: handlesCollection(limit: 10) {
              items {
                title
                byline
                tag
                url
                textColour
                handles: handlesCollection(limit: 10) {
                  items {
                    title
                    byline
                    tag
                    url
                  }
                }
              }
            }
          }
        }
        secondaryNavigation: additionalCollection {
          items {
            title
            url
          }
        }
      }
    }
  }
}`

export const headerMenuQuery = `query {
  menu: headerCollection(where: {title_contains: "Main Menu"}, limit: 1, locale: "${getLocale()}") {
    items {
      links: headerMainMenuLinksCollection(limit: 20, locale: "${getLocale()}") {
        items {
          title
          handle
          visibility
          highlightLink
          grid {
            variant
            featuredImageTitle
            featuredImageHandle
            featuredImageButtonText
            featuredImageVisibility
            featuredImage ${imageFields}
            featuredImageBadge ${badgeFields}
            promoImagePrimaryTitle
            promoImagePrimaryByline
            promoImagePrimaryHandle
            promoImagePrimaryVisibility
            promoImagePrimary ${imageFields}
            promoImagePrimaryBadge ${badgeFields}
            promoImageSecondaryTitle
            promoImageSecondaryByline
            promoImageSecondaryHandle
            promoImageSecondaryVisibility
            promoImageSecondary ${imageFields}
            promoImageSecondaryBadge ${badgeFields}
            gridLinks: linksCollection(limit: 10, locale: "${getLocale()}") {
              items {
                variant
                title
                links: linksCollection(limit: 10, locale: "${getLocale()}") {
                  items {
                    title
                    handle
                    visibility
                    badge {
                      title
                      badgeType
                      themeColour
                      small
                      iconName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`
