import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Checkout, CheckoutLineItemInput } from 'types/checkout'
import { checkoutLineItemsAdd } from '@api/graphqlShopify'
import { basketEvents } from '@helpers/cart'
import { CartState } from '../reducers/cartReducer'
import { purgeNullVariantsFromCheckout } from '../helpers'
import { updateCart, toggleCartNotification } from './cartActions'

type AddToCart = (
  itemsToAdd: CheckoutLineItemInput[],
  context: string
) => ThunkAction<Promise<Checkout>, { cart: CartState }, unknown, Action>

const addToCart: AddToCart =
  (itemsToAdd, context) => async (dispatch, getState) => {
    const { cart } = getState()

    let checkout = await checkoutLineItemsAdd(itemsToAdd)

    const purgedCheckout = await purgeNullVariantsFromCheckout(
      checkout?.lineItems
    )

    if (purgedCheckout) {
      dispatch(toggleCartNotification(true))
      checkout = await checkoutLineItemsAdd(itemsToAdd)
    }

    dispatch(updateCart(checkout))
    basketEvents({
      checkout,
      previousItems: cart.lineItems || [],
      context,
      product: itemsToAdd[0]
    })

    return checkout
  }

export default addToCart
