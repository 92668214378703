import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { trackMessage } from '@helpers/trackError'
import { getCheckoutCookie, setCheckoutCookie } from '@helpers/cart'
import readCheckout from '@store/redux/actions/readCheckout'
import Drawer from '@components/ui/Drawer'
import { Loader } from '@components/Loading/LoadingElements'

const CartSideDrawer = dynamic(() => import('./CartSideDrawer'), {
  ssr: false,
  loading: () => <Loader fullHeight={true} />
})

type CartSideDrawerOverlayProps = {
  isCartOpen?: boolean
  closeCart: () => void
  openCart: () => void
}

function CartSideDrawerOverlay({
  isCartOpen = false,
  closeCart,
  openCart
}: CartSideDrawerOverlayProps) {
  const dispatch = useDispatch()
  const router = useRouter()

  const queryCheckoutId = router.query.checkout_id as string | undefined

  useEffect(() => {
    const savedCheckoutId = getCheckoutCookie()

    if (
      savedCheckoutId &&
      queryCheckoutId &&
      queryCheckoutId !== savedCheckoutId
    ) {
      trackMessage('Saved checkout id is the NOT same as query, ignoring', {
        savedCheckoutId,
        queryCheckoutId
      })
    }

    if (!savedCheckoutId && queryCheckoutId) {
      setCheckoutCookie({ value: queryCheckoutId })
    }

    const checkoutId = savedCheckoutId || queryCheckoutId

    if (checkoutId) {
      dispatch(readCheckout(checkoutId))
    }
  }, [dispatch, queryCheckoutId])

  useEffect(() => {
    if (queryCheckoutId != null && !isCartOpen && router.route !== '/cart') {
      openCart()
    }
  }, [queryCheckoutId, router.route])

  return (
    <Drawer size="medium" isDrawerOpen={isCartOpen} onCloseDrawer={closeCart}>
      <CartSideDrawer closeCart={closeCart} />
    </Drawer>
  )
}

export default CartSideDrawerOverlay
