import trackError from '@helpers/trackError'
import { getCookieByName, setCookie, removeCookie } from '@helpers/session'
import { parse } from '@helpers/shopify'
import { COOKIE_EXPIRE_14_DAYS } from '@consts/index'
import {
  checkoutCreateMutation,
  checkoutLineItemsAddMutation,
  checkoutLineItemsUpdateMutation,
  checkoutAttributesUpdateMutation
} from './mutations'
import { readCheckoutQuery, readCustomerQuery } from './queries'
import makeRequest from './makeRequest'
import {
  ShopifyCheckoutError,
  ShopifyCheckoutQuery,
  ShopifyCheckoutLineItemsUpdate,
  ShopifyCheckoutAttributesUpdate,
  ShopifyCheckoutCreate,
  ShopifyCustomerQuery,
  ShopifyCheckoutLineItemsAdd
} from './types'
import {
  Checkout,
  CheckoutLineItemInput,
  CustomAttribute
} from 'types/checkout'
import { Customer } from 'types/customer'

function addCheckoutCookie(checkoutId: string) {
  setCookie(
    `checkout_${process.env.SHOP_LOCALE}`,
    checkoutId,
    COOKIE_EXPIRE_14_DAYS
  )
  removeCookie('th_ftv')
}

function removeCheckoutCookie() {
  removeCookie(`checkout_${process.env.SHOP_LOCALE}`)
}

function getErrorMessage(error: ShopifyCheckoutError[] | Error) {
  return Array.isArray(error) ? error[0]?.message : error.message
}

export async function readCheckout(checkoutId: string) {
  try {
    const { data, errors } = await makeRequest<ShopifyCheckoutQuery>(
      readCheckoutQuery,
      {
        checkoutId
      }
    )

    if (errors?.length) {
      throw errors
    }

    const checkout = parse(data) as Checkout

    if (!checkout) {
      throw new Error('Checkout is empty')
    }

    if (checkout.completedAt) {
      removeCheckoutCookie()
      throw new Error('This checkout was previously completed')
    }

    if (!checkout.lineItems) {
      throw new Error('lineItems missing from checkout')
    }

    return checkout
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`readCheckout failed - ${errorMessage}`), {
      checkoutId,
      error
    })
    throw error
  }
}

export async function checkoutUpdate(lineItems: CheckoutLineItemInput[] = []) {
  const checkoutId = getCookieByName(`checkout_${process.env.SHOP_LOCALE}`)

  try {
    if (!checkoutId) {
      throw Error('No checkout id')
    }

    const { data } = await makeRequest<ShopifyCheckoutLineItemsUpdate>(
      checkoutLineItemsUpdateMutation,
      {
        lineItems,
        checkoutId
      }
    )

    const { checkout, checkoutUserErrors } = parse(
      data.checkoutLineItemsUpdate
    ) as {
      checkout: Checkout
      checkoutUserErrors: ShopifyCheckoutError[]
    }

    if (checkoutUserErrors?.length) {
      throw checkoutUserErrors
    }

    addCheckoutCookie(checkout.id)

    return checkout
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`checkoutUpdate failed - ${errorMessage}`), {
      checkoutId,
      lineItems: JSON.stringify(lineItems),
      error
    })
    throw error
  }
}

export async function checkoutCustomAttributesUpdate(
  customAttributes: CustomAttribute | CustomAttribute[]
) {
  const checkoutId = getCookieByName(`checkout_${process.env.SHOP_LOCALE}`)

  try {
    if (!checkoutId) {
      throw Error('No checkout id')
    }

    const { data } = await makeRequest<ShopifyCheckoutAttributesUpdate>(
      checkoutAttributesUpdateMutation,
      {
        checkoutId,
        input: { customAttributes }
      }
    )

    const { checkout, checkoutUserErrors } = parse(
      data.checkoutAttributesUpdateV2
    ) as {
      checkout: Checkout
      checkoutUserErrors: ShopifyCheckoutError[]
    }

    if (checkoutUserErrors?.length) {
      throw checkoutUserErrors
    }

    addCheckoutCookie(checkout.id)

    return checkout
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(
      new Error(`checkoutCustomAttributesUpdate failed - ${errorMessage}`),
      {
        checkoutId,
        customAttributes: JSON.stringify(customAttributes),
        error
      }
    )
    throw error
  }
}

export async function createCheckout(lineItems: CheckoutLineItemInput[]) {
  try {
    const { data } = await makeRequest<ShopifyCheckoutCreate>(
      checkoutCreateMutation,
      {
        lineItems
      }
    )

    const { checkout, checkoutUserErrors } = parse(data.checkoutCreate) as {
      checkout: Checkout
      checkoutUserErrors: ShopifyCheckoutError[]
    }

    if (checkoutUserErrors?.length) {
      throw checkoutUserErrors
    }

    addCheckoutCookie(checkout.id)

    return checkout
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`createCheckout failed - ${errorMessage}`), {
      lineItems: JSON.stringify(lineItems),
      error
    })
    throw error
  }
}

export async function readCustomer(customerAccessToken: string) {
  try {
    const { data, errors } = await makeRequest<ShopifyCustomerQuery>(
      readCustomerQuery,
      {
        customerAccessToken
      }
    )

    if (errors) throw errors

    return parse(data) as { customer: Customer }
  } catch (e) {
    const error = e as Error
    trackError(new Error('Error at reading the customer'), {
      error,
      customerAccessToken
    })
    return { customer: undefined }
  }
}

export async function checkoutLineItemsAdd(lineItems: CheckoutLineItemInput[]) {
  const checkoutId = getCookieByName(`checkout_${process.env.SHOP_LOCALE}`)

  if (!checkoutId) {
    return createCheckout(lineItems)
  }

  try {
    const { data } = await makeRequest<ShopifyCheckoutLineItemsAdd>(
      checkoutLineItemsAddMutation,
      {
        lineItems,
        checkoutId
      }
    )

    const { checkout, checkoutUserErrors } = parse(
      data.checkoutLineItemsAdd
    ) as {
      checkout: Checkout
      checkoutUserErrors: ShopifyCheckoutError[]
    }

    if (checkoutUserErrors?.length) {
      throw checkoutUserErrors
    }

    addCheckoutCookie(checkout.id)

    return checkout
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`checkoutLineItemsAdd failed - ${errorMessage}`), {
      checkoutId,
      lineItems: JSON.stringify(lineItems),
      error
    })
    throw error
  }
}
