const discountValue = `... on MoneyV2 {
  __typename
  amount
}`

const fragment = `{
  updatedAt
  completedAt
  email
  lineItemsSubtotalPrice {
    ${discountValue}
  }
  discountApplications(first: 5) {
    edges {
      node {
        ... on DiscountCodeApplication {
          code
          value {
            ${discountValue}
            ... on PricingPercentageValue {
              percentage
            }
          }
        }
      }
    }
  }
  webUrl
  id
  totalPriceV2 {
    amount
    currencyCode
  }
  lineItems(first:100) {
    edges {
      node {
        discountAllocations {
          allocatedAmount {
            amount
          }
          discountApplication {
            ... on DiscountCodeApplication {
              code
              value {
                ${discountValue}
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
            ... on ScriptDiscountApplication {
              title
              value {
                ${discountValue}
              }
            }
            ... on AutomaticDiscountApplication {
              title
              value {
                ${discountValue}
              }
            }
          }
        }
        id
        quantity
        variant {
            sku
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
            }
            quantityAvailable
            product {
              id
              productType
              title
              url : onlineStoreUrl
              options {
                name
                values
              }
            }
            image {
              originalSrc
            }
            id
          }
        customAttributes {
          key
          value
        }
      }
    }
  }
  customAttributes {
    key
    value
  }
}`
export default fragment
