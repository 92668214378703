import { readCheckout as readCheckoutService } from '@api/graphqlShopify'
import { setCheckoutCookie } from '@helpers/cart'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Checkout } from 'types/checkout'
import { purgeNullVariantsFromCheckout } from '../helpers'
import { updateCart, toggleCartNotification, resetCart } from './cartActions'

type ReadCheckoutReturnType = ThunkAction<Promise<void>, void, unknown, Action>

function readCheckout(checkoutId: string): ReadCheckoutReturnType {
  return async dispatch => {
    try {
      let checkout = (await readCheckoutService(checkoutId)) as Checkout

      const purgedCheckout = await purgeNullVariantsFromCheckout(
        checkout?.lineItems
      )

      if (purgedCheckout) {
        checkout = purgedCheckout
        dispatch(toggleCartNotification(true))
      }

      dispatch(updateCart(checkout))
    } catch (error) {
      setCheckoutCookie({ expiration: -1 })
      dispatch(resetCart())
    }
  }
}

export default readCheckout
