import { LineItem, Checkout, CustomAttribute } from 'types/checkout'

export type CartState =
  | {
      id: string | undefined
      lineItems: LineItem[]
      totalPriceV2: {
        amount: string
        currencyCode?: string
      }
      customAttributes: CustomAttribute[]
      webUrl: string
    }
  | Checkout

export type UpdateCartAction = {
  type: 'UPDATE_CART'
  payload: Checkout
}
export type ResetCartAction = {
  type: 'RESET_CART'
}

type CartAction = UpdateCartAction | ResetCartAction

const initState = {
  id: undefined,
  lineItems: [],
  totalPriceV2: { amount: '0.0', currencyCode: undefined },
  customAttributes: [],
  webUrl: ''
}

export default function cartReducer(
  state: CartState = initState,
  action: CartAction
): CartState {
  switch (action.type) {
    case 'UPDATE_CART':
      return { ...state, ...action.payload }

    case 'RESET_CART':
      return initState

    default:
      return state
  }
}
