import { Fragment, ReactNode } from 'react'
import { checkStoreIs, getLink } from '@helpers/env'
import { ReactComponent as TwitterIcon } from '@svgs/twitter.svg'
import { ReactComponent as InstagramIcon } from '@svgs/instagram.svg'
import { ReactComponent as FacebookIcon } from '@svgs/facebook.svg'
import Translations from '@helpers/translations'
import styles from './styles.module.scss'
import socialLinkStyles from './social-link.module.scss'

const getLinks = () => {
  const links = [
    {
      href: 'https://spoke.trustkeith.co',
      text: Translations.localise('common.footer.privacy'),
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      href: '/pages/terms',
      text: Translations.localise('common.footer.terms')
    }
  ]
  if (checkStoreIs('de')) {
    links.push({
      href: '/pages/impressum',
      text: 'Impressum'
    })
  }
  return links
}

type SocialIconProps = {
  url: string
  children: ReactNode
}
function SocialIcon({ url, children }: SocialIconProps) {
  return (
    <li className={socialLinkStyles.socialItem}>
      <a
        className={socialLinkStyles.socialLink}
        href={getLink(url)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    </li>
  )
}

function SocialContent() {
  const links = getLinks()
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  return (
    <>
      <ul
        className={`${styles.socialLinkWrapper} ${styles.socialLinkWrapper_light}`}
      >
        <SocialIcon url="https://twitter.com/spoke_london">
          <TwitterIcon />
        </SocialIcon>
        <SocialIcon url="https://www.instagram.com/spoke_london/">
          <InstagramIcon />
        </SocialIcon>
        <SocialIcon url="https://www.facebook.com/spokefit">
          <FacebookIcon />
        </SocialIcon>
      </ul>
      <div className={styles.footerSmallprintFirst}>
        <span className={styles.footerSmallprintCopyright}>
          &copy; {currentYear} Spoke London<sup>TM</sup>&nbsp; / &nbsp;
        </span>
        {links.map((link, i) => {
          const isLastLink = i + 1 === links.length
          return (
            <Fragment key={link.href}>
              <a {...link} href={getLink(link.href)}>
                {link.text}
              </a>
              {isLastLink ? <span /> : <span>&nbsp; / &nbsp;</span>}
            </Fragment>
          )
        })}
      </div>
    </>
  )
}

export default SocialContent
