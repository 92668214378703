import { useRef } from 'react'
import { HeaderLink } from 'types/header'
import { debounce } from '@helpers/utils'
import hoverIntent from '@components/Header/helpers/hoverIntent'
import linkStyles from './desktopMenuLink.module.scss'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/NewHeader/consts'
import { analytics } from '@helpers/analytics'
import { getLink } from '@helpers/env'

type DesktopMenuLinkProperties = {
  link: HeaderLink
  active: boolean
  hasSubmenu: boolean
  handleToggleMegamenu: (title: string) => void
}

export default function DesktopMenuLink({
  link,
  active,
  hasSubmenu,
  handleToggleMegamenu
}: DesktopMenuLinkProperties) {
  const linkRef = useRef<HTMLAnchorElement>(null)

  const highlightLink = !!link.highlightLink || link.highlightLink

  const handleOnClick = () => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
      label: link.title
    })
  }

  return (
    <a
      ref={linkRef}
      href={getLink(link.handle)}
      role="menuitem"
      aria-haspopup={hasSubmenu}
      className={`${linkStyles.DesktopMenuLink} ${
        active ? linkStyles.DesktopMenuLink__active : ''
      } ${highlightLink ? linkStyles.DesktopMenuLink__highlight : ''}`}
      onClick={() => handleOnClick()}
      onMouseEnter={e => {
        e.stopPropagation()
        debounce(
          hoverIntent(
            linkRef.current as HTMLAnchorElement,
            () => {
              return handleToggleMegamenu(link.title)
            },
            () => {
              return false
            }
          ),
          100
        )
      }}
    >
      {link.title}
    </a>
  )
}
