import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { checkoutCustomAttributesUpdate } from '@api/graphqlShopify'
import { CartState } from '@store/redux/reducers/cartReducer'
import { Checkout, CustomAttribute } from 'types/checkout'
import { purgeNullVariantsFromCheckout } from '../helpers'
import { updateCart, toggleCartNotification } from './cartActions'

type UpdateCheckoutCustomAttributes = (
  customAttributes: CustomAttribute | CustomAttribute[],
  context: string
) => ThunkAction<Promise<Checkout>, { cart: CartState }, unknown, Action>

const updateCheckoutCustomAttributes: UpdateCheckoutCustomAttributes =
  customAttributes => {
    return async dispatch => {
      let checkout = await checkoutCustomAttributesUpdate(customAttributes)

      const purgedCheckout = await purgeNullVariantsFromCheckout(
        checkout?.lineItems
      )

      if (purgedCheckout) {
        dispatch(toggleCartNotification(true))
        checkout = await checkoutCustomAttributesUpdate(customAttributes)
      }

      dispatch(updateCart(checkout))

      return checkout
    }
  }

export default updateCheckoutCustomAttributes
