import { CustomAttribute, LineItem } from 'types/checkout'

export function findCustomAttribute(
  customAttributes: CustomAttribute[],
  key: string
) {
  if (!customAttributes) return null
  const customAttribute = customAttributes.find(att => att.key === key)
  return (customAttribute?.value || '').toString()
}

export function sortLineItemsByCustomAttribute(
  lineItems: LineItem[],
  customAttribute: string
) {
  return [...lineItems].sort((a, b) => {
    const aCustomAttribute = findCustomAttribute(
      a.customAttributes,
      customAttribute
    )
    const bCustomAttribute = findCustomAttribute(
      b.customAttributes,
      customAttribute
    )
    if (!bCustomAttribute || !aCustomAttribute) return 0
    if (bCustomAttribute > aCustomAttribute) return 1
    if (bCustomAttribute < aCustomAttribute) return -1
    return 0
  })
}

const customAttributesSortPriority: Record<string, number> = {
  Waist: 1,
  Build: 2,
  Leg: 3,
  ShortsLeg: 3,
  Size: 1,
  Cut: 2,
  Length: 3
}

export function sortCustomAttributes(lineItems: LineItem[]) {
  return lineItems.map(lineItem => {
    return {
      ...lineItem,
      customAttributes: lineItem.customAttributes.sort((a, b) => {
        const priorityA = customAttributesSortPriority[a.key]
        const priorityB = customAttributesSortPriority[b.key]

        if (priorityA < priorityB) {
          return -1
        }

        if (priorityA > priorityB) {
          return 1
        }

        return 0
      })
    }
  })
}
