import React, { useState } from 'react'
import {
  AppContext as NextJSAppContext,
  AppInitialProps,
  AppProps
} from 'next/app'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, Store } from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'
import rootReducers from '@store/redux/reducers'
import { analytics } from '@helpers/analytics'
import trackError from '@helpers/trackError'
import { isProdEnv, isBrowser } from '@helpers/env'
import AppComponent from '@components/App'
import '../components/global.styles.scss'
import {
  fetchContentfulHeaderFlashBanner,
  fetchContentfulHeaderMobile,
  fetchContentfulHeader,
  fetchContentfulHeaderDesktop
} from '@api/graphqlContentFul/header'
import { getValueFromObject } from '@helpers/utils'
import { Router } from 'next/router'
import { HeaderState } from '@store/redux/reducers/otherReducers'
import { EmptyObject, GenericFunction } from 'types/helpers'
import { PostHog } from '../global.d'
import { FlashBannerItem } from 'types/contentful/graphQlApi'
import AppContext from '@components/AppContext'
import { HeaderProps, HeaderTheme, MenuLinks } from 'types/header'

const NO_LAYOUT = ['fit-finder', 'returns']

const IGNORED_SENTRY_ERRORS = [
  'Failed to load https://www.googletagmanager.com/'
]

function getIgnoredSentryError(eventValue: string) {
  let ignoredError = ''
  IGNORED_SENTRY_ERRORS.forEach(error => {
    if (eventValue.includes(error)) {
      ignoredError = error
    }
  })
  return ignoredError
}

if (isBrowser()) {
  Sentry.init({
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // Facebook borked
      'fb_xd_fragment',
      `Can't find variable: _AutofillCallbackHandler`
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Bing
      /bat.bing.com/i,
      // tiktok
      /analytics.tiktok.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
      /googleads.g.doubleclick.net/i,
      /\/tag\.js/i,
      /assets\/smart-tag/i,
      /sezzle-widget.min.js/i,
      /dev.visualwebsiteoptimizer.com/i,
      /staticw2.yotpo.com/i,
      /googletagmanager.com/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^@safari-web-extension:/i
    ],
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.API_ENV,
    release: process.env.NEXT_BUILD_ID,
    dist: process.env.NEXT_BUILD_ID,
    dsn: 'https://114158a216294a3aafff074e7efc0227@o490003.ingest.sentry.io/5553275',
    autoSessionTracking: true,
    integrations: [],
    beforeSend: event => {
      const eventValues = event.exception?.values
      if (
        eventValues &&
        eventValues.find(
          event =>
            event.value &&
            event.value.includes(getIgnoredSentryError(event.value))
        )
      ) {
        return null
      }
      return event
    }
  })

  window.postHogAsyncLoader = new Promise(resolve => {
    window.analytics?.ready(() => {
      if (process.env.NODE_ENV !== 'development') {
        window.posthog.init(process.env.POSTHOG_API_KEY, {
          api_host: 'https://eu.posthog.com',
          disable_session_recording: true,
          segment: window.analytics, // Pass window.analytics here - NOTE: `window.` is important
          capture_pageview: false, // You want this false if you are going to use segment's `analytics.page()` for pageviews
          rageclick: true, // capturing when a user clicks three or more times (maximum 1s between each click) without moving their mouse
          loaded: (posthog: PostHog) => {
            posthog.onFeatureFlags(() => {
              if (posthog.isFeatureEnabled('sc_recording')) {
                posthog.startSessionRecording()
              }
            })
            resolve(posthog)
          }
        })
      }
    })
  })
}

let devtools: GenericFunction = f => f
if (process.browser && window.__REDUX_DEVTOOLS_EXTENSION__ && !isProdEnv()) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
}

const makeStore: (
  initialState: Record<string, unknown>
) => Store = initialState =>
  createStore(
    rootReducers,
    initialState,
    compose(
      applyMiddleware(thunk), // Add additional middleware here
      devtools
    )
  )

const store = makeStore({})

export function reportWebVitals(metric: {
  label: string
  name: string
  value: number
}) {
  if (metric.label === 'web-vital') {
    analytics(
      'Web Vitals',
      {
        category: 'Web Vitals',
        label: metric.name,
        action: metric.name,
        value: Math.round(
          metric.name === 'CLS' ? metric.value * 1000 : metric.value
        ),
        nonInteraction: 1
      },
      {
        integrations: {
          All: false,
          'Google Analytics': true,
          'Google Analytics 4': true
        }
      }
    )
  }
}

export type FlashBanner =
  | FlashBannerItem
  | EmptyObject
  | { showBanner: boolean }

type PageProps = Record<string, unknown> & {
  statusCode: number
  pageEntries?: Array<Record<string, string | undefined>>
}

type AppOwnProps = {
  handle: string
  route: Router['route']
  statusCode: number
  flashBanner: FlashBanner
  header: HeaderProps
  desktop: HeaderState['desktop']
  mobile: MenuLinks | EmptyObject
  pageProps: PageProps
}

function MyApp({
  Component,
  pageProps,
  handle,
  route,
  flashBanner,
  header,
  desktop,
  mobile,
  statusCode
}: AppProps & AppOwnProps) {
  const pageHeaderTheme =
    (pageProps?.pageEntries?.find(
      (entry: { headerTheme?: HeaderTheme }) => entry?.headerTheme !== undefined
    )?.headerTheme as HeaderTheme) || ('default' as HeaderTheme)

  const [headerTheme, setHeaderTheme] = useState(pageHeaderTheme)

  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{
          flashBanner,
          desktop,
          mobile,
          header,
          headerTheme,
          setHeaderTheme
        }}
      >
        <AppComponent
          statusCode={statusCode}
          noLayout={NO_LAYOUT.includes(handle) || NO_LAYOUT.includes(route)}
        >
          <Component {...pageProps} />
        </AppComponent>
      </AppContext.Provider>
    </Provider>
  )
}

MyApp.getInitialProps = async (
  context: NextJSAppContext
): Promise<(AppOwnProps & AppInitialProps) | undefined> => {
  try {
    const flashBanner = await fetchContentfulHeaderFlashBanner()
    const header = await fetchContentfulHeader()
    const desktop = await fetchContentfulHeaderDesktop()
    const mobile = await fetchContentfulHeaderMobile()
    const flashBannerResponse = getValueFromObject(
      flashBanner,
      'menu.items[0].flashBannerItem',
      {}
    )
    const flashBannerItem = {
      ...flashBannerResponse,
      showBanner: Object.keys(flashBannerResponse).length > 0
    }

    const componentPageProps = (
      context.Component.getInitialProps
        ? await context.Component.getInitialProps(context.ctx)
        : { statusCode: 200 }
    ) as PageProps

    return {
      handle: context.router.query.pagesHandle as string,
      flashBanner: flashBannerItem,
      route: context.router.route,
      statusCode: componentPageProps?.statusCode,
      mobile: getValueFromObject(mobile, 'menu.items[0].mobile', {}),
      desktop: getValueFromObject(desktop, 'menu.items[0].desktop', {}),
      header: getValueFromObject(header, 'menu.items[0].links', {}),
      pageProps: {
        ...componentPageProps
      }
    }
  } catch (e) {
    const menuError = new Error('Could not get menu from contentful')
    const error = e instanceof Error ? e : menuError
    trackError(new Error('Could not get menu from contentful'), { error })
    return undefined
  }
}

export default MyApp
