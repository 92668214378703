import MenuLink from '@components/NewHeader/shared/MenuLink'
import styles from './styles.module.scss'
import { HeaderGridLinks } from 'types/header'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/NewHeader/consts'

export default function SecondaryLinksContainer({
  items,
  currentNav
}: {
  items: HeaderGridLinks
  currentNav: string
}) {
  const { links, title } = items || []
  const visibleLinks = links?.items.filter(link => link.visibility)

  const handleClick = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      contentType: 'Secondary Links'
    })
  }

  return (
    <ul className={styles.SecondaryLinksContainer}>
      {title && <h3>{title}</h3>}
      {visibleLinks?.map(({ title, handle, badge }, index) => (
        <li key={`secondary-${title}-${index}`}>
          <MenuLink
            title={title}
            link={handle}
            badge={badge}
            onClick={handleClick}
          />
        </li>
      ))}
    </ul>
  )
}
