import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { checkoutUpdate } from '@api/graphqlShopify'
import { basketEvents } from '@helpers/cart'
import { Checkout, CheckoutLineItemInput } from 'types/checkout'
import { CartState } from '../reducers/cartReducer'
import { purgeNullVariantsFromCheckout } from '../helpers'
import { updateCart, toggleCartNotification } from './cartActions'

type UpdateCheckout = (
  itemsToUpdate: CheckoutLineItemInput[],
  context: string
) => ThunkAction<Promise<Checkout>, { cart: CartState }, unknown, Action>

const updateCheckout: UpdateCheckout = (itemsToUpdate, context) => {
  return async (dispatch, getState) => {
    const { cart } = getState()

    let checkout = await checkoutUpdate(itemsToUpdate)

    const purgedCheckout = await purgeNullVariantsFromCheckout(
      checkout?.lineItems
    )

    if (purgedCheckout) {
      dispatch(toggleCartNotification(true))
      checkout = await checkoutUpdate(itemsToUpdate)
    }

    dispatch(updateCart(checkout))
    basketEvents({
      checkout,
      previousItems: cart.lineItems || [],
      context,
      product: itemsToUpdate[0]
    })

    return checkout
  }
}

export default updateCheckout
