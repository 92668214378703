import { OPEN_FIT_FINDER } from '@consts/index'
import { getHelpdeskLink, checkStoreIs } from '@helpers/env'
import Translations from '@helpers/translations'

const COLOUR_COLLECTIONS_URLS = {
  'black-chinos': '/collections/black-chinos',
  'navy-chinos': '/collections/navy-chinos',
  'beige-chinos': '/collections/beige-chinos',
  'black-tops': '/collections/black-tops',
  'blue-tops': '/collections/blue-tops',
  'green-tops': '/collections/green-tops'
} as const

const footerColumnsLinks = [
  {
    title: Translations.localise('common.footer.about'),
    childLinks: [
      {
        title: Translations.localise('common.footer.fit-finder'),
        url: `#${OPEN_FIT_FINDER}`,
        rawUrl: true
      },
      {
        title: Translations.localise('common.footer.refer-a-friend'),
        url: '/pages/refer-a-friend'
      },
      {
        title: Translations.localise('common.footer.about'),
        url: '/pages/about'
      },
      {
        title: Translations.localise('common.footer.careers'),
        url: 'https://spoke.workable.com/',
        openNewWindow: true
      }
    ]
  },
  {
    title: Translations.localise('common.footer.help'),
    childLinks: [
      {
        title: Translations.localise('common.footer.faq'),
        url: `${getHelpdeskLink()}`,
        openNewWindow: true
      },
      {
        title: Translations.localise('common.footer.return-policy'),
        url: '/pages/return-policy'
      },
      {
        title: Translations.localise('common.footer.size-chart'),
        url: '/pages/trousers-size-chart'
      },
      {
        title: Translations.localise('common.footer.contact-us'),
        url: '/pages/contact-us'
      },
      {
        title: Translations.localise('common.footer.arrange-return'),
        url: '/pages/returns'
      }
    ]
  },
  {
    title: Translations.localise('common.footer.shop'),
    childLinks: [
      {
        title: Translations.localise('common.footer.chinos'),
        url: '/collections/trousers'
      },
      {
        title: Translations.localise('common.footer.formal-trousers'),
        url: '/collections/smart-trousers'
      },
      {
        title: Translations.localise('common.footer.casual-trousers'),
        url: '/collections/casual-trousers'
      },
      {
        title: Translations.localise('common.footer.denim'),
        url: '/collections/denim'
      },
      {
        title: Translations.localise('common.footer.swims'),
        url: '/collections/swims'
      },
      {
        title: Translations.localise('common.footer.shorts'),
        url: '/collections/shorts'
      },
      {
        title: Translations.localise('common.footer.gifts'),
        url: '/collections/the-gift-edit'
      }
    ]
  },
  {
    title: Translations.localise('common.footer.top-colours'),
    childLinks: [
      {
        title: Translations.localise('common.footer.black-chinos'),
        url: COLOUR_COLLECTIONS_URLS['black-chinos']
      },
      {
        title: Translations.localise('common.footer.navy-chinos'),
        url: COLOUR_COLLECTIONS_URLS['navy-chinos']
      },
      {
        title: Translations.localise('common.footer.beige-chinos'),
        url: COLOUR_COLLECTIONS_URLS['beige-chinos']
      },
      {
        title: Translations.localise('common.footer.black-tops'),
        url: COLOUR_COLLECTIONS_URLS['black-tops']
      },
      {
        title: Translations.localise('common.footer.blue-tops'),
        url: COLOUR_COLLECTIONS_URLS['blue-tops']
      },
      {
        title: Translations.localise('common.footer.green-tops'),
        url: COLOUR_COLLECTIONS_URLS['green-tops']
      }
    ]
  }
]

if (checkStoreIs('de')) {
  footerColumnsLinks[1].childLinks.push({
    title: 'Impressum',
    url: '/pages/impressum'
  })
}

export default footerColumnsLinks
