import checkoutFragment from '../fragments/checkout'
import checkoutUserErrorsFragment from '../fragments/checkoutUserErrors'

const mutation = `mutation ($lineItems : [CheckoutLineItemInput!]) {
  checkoutCreate(input: {
    lineItems: $lineItems
  }){
    checkoutUserErrors ${checkoutUserErrorsFragment}
    checkout ${checkoutFragment}
  }
}
`
export default mutation
