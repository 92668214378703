import { HeaderGrid } from 'types/header'
import styles from './styles.module.scss'
import PrimaryLinksContainer from './shared/PrimaryLinksContainer'
import SecondaryLinksContainer from './shared/SecondaryLinksContainer'
import FeaturedImage from '@components/NewHeader/shared/FeaturedImage'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/NewHeader/consts'

const componentMap = {
  primary: PrimaryLinksContainer,
  secondary: SecondaryLinksContainer
}

export default function DefaultGrid({
  grid,
  currentNav
}: {
  grid: HeaderGrid
  currentNav: string
}) {
  const { gridLinks } = grid || []

  const handleClickFeaturedImage = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      columnType: 'Primary Image'
    })
  }

  return (
    <div className={styles.Megamenu__Grid}>
      <div className={styles.Megamenu__Grid_links}>
        {gridLinks?.items.map((gridLink, index) => {
          const Component = componentMap[gridLink.variant]
          return (
            <Component
              key={`${gridLink.title}-${index}`}
              currentNav={currentNav}
              items={{ ...gridLink }}
            />
          )
        })}
      </div>
      {grid.featuredImageVisibility ? (
        <div className={styles.Megamenu__Grid_featuredImage}>
          <FeaturedImage
            title={grid.featuredImageTitle}
            handle={grid.featuredImageHandle}
            buttonText={grid.featuredImageButtonText}
            image={grid.featuredImage}
            width={490}
            height={370}
            onClick={handleClickFeaturedImage}
          />
        </div>
      ) : null}
    </div>
  )
}
