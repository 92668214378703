/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import addToCartAction from '@store/redux/actions/addToCart'
import updateCheckout from '@store/redux/actions/updateCheckout'
import updateCheckoutCustomAttributes from '@store/redux/actions/updateCheckoutCustomAttributes'
import { RootState } from '@store/redux/reducers'
import { LineItem, CustomAttribute } from 'types/checkout'
import { CartState } from '@store/redux/reducers/cartReducer'
import useABtests from '@helpers/hooks/useABtests'
import { getAbTestCheckoutAttributes } from '@helpers/cart'
import { getCheckoutUpdatePayload } from '../helpers'
import { sortCustomAttributes } from '@helpers/cart/customAttributes'
import { SubmitButtonHandlers } from '@components/ui/Button/SubmitButton'

export type ProductAddToCart = {
  quantity: number
  variantId: string
  customAttributes: CustomAttribute[]
}

export type AddToCart = (
  products: ProductAddToCart[],
  context?: string
) => Promise<void>

export type UpdateCartQuantity = (
  id: string,
  variantId: string,
  quantity: number,
  context?: string
) => Promise<void>

export type UpdateGiftMessage = (message: string) => Promise<void>

type UseCartReturn = {
  cart: CartState
  lineItems: LineItem[]
  isLoadingCartItems: boolean
  updateCartQuantity: UpdateCartQuantity
  addToCart: AddToCart
  updateGiftMessage: UpdateGiftMessage
  navigateToCheckout: ({ triggerButtonReady }: SubmitButtonHandlers) => void
}

export function useCart(): UseCartReturn {
  const dispatch = useDispatch()

  const cart = useSelector<RootState, CartState>(state => state.cart)

  const [isLoadingCartItems, setIsLoadingCartItems] = useState(false)
  const [{ lineItems }, setLocalCart] = useState(cart)

  const { testsVariants } = useABtests()

  const checkoutUrl = (cart.webUrl || '').replace(/origin\./, '')

  useEffect(() => {
    setLocalCart({...cart, lineItems: sortCustomAttributes(cart.lineItems)})
    setIsLoadingCartItems(false)
  }, [cart])

  const addToCart: AddToCart = async (payload, context = 'Cart') => {
    try {
      setIsLoadingCartItems(true)
      await dispatch(addToCartAction(payload, context))
      setIsLoadingCartItems(false)
    } catch (e) {
      setIsLoadingCartItems(false)
    }
  }

  const updateGiftMessage: UpdateGiftMessage = async message => {
    try {
      setIsLoadingCartItems(true)
      const payload = message
        ? {
            key: 'gift_message',
            value: message
          }
        : []
      await dispatch(updateCheckoutCustomAttributes(payload, 'Cart'))
      setIsLoadingCartItems(false)
    } catch (e) {
      setIsLoadingCartItems(false)
    }
  }

  const updateCartQuantity: UpdateCartQuantity = async (
    id,
    variantId,
    quantity,
    context = 'Cart'
  ) => {
    if (isLoadingCartItems) return

    try {
      setIsLoadingCartItems(true)
      const { payload, newItems } = getCheckoutUpdatePayload({
        id,
        variantId,
        quantity,
        lineItems
      })
      setLocalCart({ ...cart, lineItems: newItems })
      await dispatch(updateCheckout(payload, context))
      setIsLoadingCartItems(false)
    } catch (e) {
      setIsLoadingCartItems(false)
    }
  }

  const navigateToCheckout = async ({
    triggerButtonReady
  }: SubmitButtonHandlers) => {
    const checkoutAttributes = [
      ...getAbTestCheckoutAttributes(testsVariants),
      ...cart.customAttributes
    ]

    if (checkoutAttributes.length > 0) {
      await dispatch(updateCheckoutCustomAttributes(checkoutAttributes, 'Cart'))
    }

    const location = (window as Window).location

    triggerButtonReady()

    location.href = checkoutUrl
  }

  return {
    cart,
    lineItems,
    isLoadingCartItems,
    addToCart,
    updateGiftMessage,
    updateCartQuantity,
    navigateToCheckout
  }
}
