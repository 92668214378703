// utils.js
import { isBrowser, isProdEnv } from '@helpers/env'

export const getWidth = () => {
  return isBrowser() ? window.innerWidth : 0
}

export const getHeight = () => {
  return isBrowser() ? window.innerHeight : 0
}

export const scrollToTop = (ref = 0) =>
  window.scrollTo({ top: ref, left: 0, behavior: 'smooth' })

export const cmToIn = (cm: number, dec = 1) => {
  const val = cm * 0.393701

  if (typeof dec === 'number' && typeof cm === 'number') {
    return parseFloat(val.toFixed(dec))
  }

  return cm !== null ? val : ''
}

export const formatDate = (maxDate: Date | string) => {
  const returnedDate = new Date(maxDate)
  returnedDate.setDate(returnedDate.getDate())
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).format(returnedDate)
}

export const upperFirstLetter = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  timeout: number
): (...args: Parameters<T>) => void {
  let timer: ReturnType<typeof setTimeout> | undefined

  return (...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      callback(...args)
    }, timeout)
  }
}

export function arrayPartition<T>(
  arrayToPartition: T[] = [],
  predicate = (i: T): boolean => !!i
) {
  const result: [T[], T[]] = [[], []]
  arrayToPartition.forEach((item: T) => {
    result[predicate(item) ? 0 : 1].push(item)
  })
  return result
}

export function getValueFromObject<T>(
  obj: Record<string, any> = {},
  path = '',
  defaultValue: T
): T {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res: Record<string, any>, key: string) =>
          res !== null && res !== undefined ? res[key] : res,
        obj
      )
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return (result == null || result === obj ? defaultValue : result) as T
}

export function getQueryStringFromObject({
  queryObject = {},
  exclude = []
}: {
  queryObject: Record<string, unknown>
  exclude: string[]
}) {
  return Object.keys(queryObject).reduce((result, key, index) => {
    if (exclude.includes(key)) return result
    return `${result}${result && index > 0 ? '&' : ''}${key}=${
      queryObject[key]
    }`
  }, '')
}

export function insertStringAt(
  { originalString = '', stringToInsert = '', position = 0 } = {
    originalString: '',
    stringToInsert: '',
    position: 0
  }
) {
  return `${originalString.slice(
    0,
    position
  )}${stringToInsert}${originalString.slice(position)}`
}

export function getMentionMeUrl() {
  return `https://${isProdEnv() ? 'tag' : 'tag-demo'}.mention-me.com/api/v2`
}

export function throttle(func = (i: unknown) => i, delay = 0) {
  let inThrottle: boolean
  return (...args: unknown[]) => {
    if (!inThrottle) {
      // eslint-disable-next-line prefer-spread
      // @ts-expect-error: Can't fix type for this args spread
      func(...args)
      inThrottle = true
      setTimeout(() => {
        inThrottle = false
      }, delay)
    }
  }
}

export function isEmptyObject(obj: unknown) {
  return JSON.stringify(obj) === '{}'
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export function objectOmit<T, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> {
  const result = { ...obj }
  keys?.forEach(key => delete result[key])
  return result
}

export function joinStringWithDashes(value = ''): string {
  if (typeof value !== 'string') return ''
  return value?.trim().toLowerCase().replace(/\s+/g, '-')
}

export function removeQueryParamsFromUrl(value = ''): string {
  if (!value) return value
  const urlObj = new URL(value)
  urlObj.search = ''
  return urlObj.toString().replace(/\/$/, '')
}

export const objectKeys: <Obj>(o: Obj) => (keyof Obj)[] = Object.keys

export function checkIsDateMoreThanOneYearAgo(
  date: string | undefined = ''
): boolean {
  const currentDate = new Date(date)
  const oneYearAgo = new Date()
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

  return currentDate < oneYearAgo
}

export function isEven(number: number) {
  return number % 2 === 0
}
