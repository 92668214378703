import { ReactNode } from 'react'
import { getLink } from '@helpers/env'
import classNames from 'classnames'
import Translations from '@helpers/translations'
import styles from './styles.module.scss'

const GDPR_DATA = {
  gdpr_accepts_marketing: 'Don&#39;t send me offers and updates from Spoke.',
  gdpr_terms_url: '/pages/terms',
  gdpr_privacy_url: 'https://spoke.trustkeith.co'
}

type FieldsLegalTextProps = {
  widthStyle?: string
  lightStyle?: string
  extraContent?: ReactNode
}

export const FieldsLegalText = ({
  widthStyle,
  lightStyle,
  extraContent
}: FieldsLegalTextProps) => {
  const classes = classNames(styles.gdprFields, {
    [styles.gdprFieldsNarrow]: widthStyle === 'narrow',
    [styles.gdprFieldsHidden]: widthStyle === 'hidden'
  })

  const textColor = { color: lightStyle }
  return (
    <div className={classes}>
      {extraContent && extraContent}
      <p className={styles.gdprTerms} style={textColor}>
        {!extraContent && <>{Translations.localise('common.gdpr.terms-1')} </>}
      </p>
      <p className={styles.gdprTerms} style={textColor}>
        {Translations.localise('common.gdpr.terms-2')}{' '}
        <a
          href={getLink(GDPR_DATA.gdpr_terms_url)}
          className={styles.gdprLink}
          target="_blank"
          rel="noopener noreferrer"
          style={textColor}
        >
          {Translations.localise('common.gdpr.terms-3')}
        </a>{' '}
        {Translations.localise('common.gdpr.terms-4')}{' '}
        <a
          href={getLink(GDPR_DATA.gdpr_privacy_url)}
          className={styles.gdprLink}
          target="_blank"
          rel="noopener noreferrer"
          style={textColor}
        >
          {Translations.localise('common.gdpr.terms-5')}
        </a>
      </p>
    </div>
  )
}
