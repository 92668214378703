import contentFulAPI from '@api/contentful'
import trackError from '@helpers/trackError'
import { objectKeys } from '@helpers/utils'
import { JsonItem } from 'types/contentful'
import { EmptyObject } from 'types/helpers'

function unNestJson(
  item: JsonItem,
  json: {
    [key: string]: JsonItem | string
  }
) {
  let returnData: JsonItem = json

  if (!item) return {}
  if (!!item.nodeType) return item

  objectKeys(item).map(key => {
    const itemData = item[key] as JsonItem
    if (key === 'fields' && item.sys?.contentType) {
      returnData = {
        ...returnData,
        ...unNestJson(itemData, {}),
        type: item.sys.contentType.sys.id
      }
    } else if (key === 'fields') {
      returnData = { ...returnData, ...unNestJson(itemData, {}) }
    } else if (typeof itemData === 'object' && key !== 'sys') {
      returnData = { ...returnData, [key]: unNestJson(itemData, {}) }
    } else if (typeof itemData !== 'object') {
      returnData = { ...returnData, [key]: item[key] }
    }
  })

  return returnData
}

function unNestArray(item: JsonItem, json: JsonItem[]): JsonItem[] {
  let returnData: JsonItem[] = json

  if (!item) return []
  Object.keys(item).map(key => {
    const itemData = item[key] as JsonItem
    if (key === 'fields') {
      // is a 'field' type
      returnData = [...returnData, { ...unNestJson(itemData, {}) }]
    } else if (typeof itemData === 'object' && Array.isArray(itemData)) {
      const data = unNestJson(itemData, {})
      returnData = [
        ...returnData,
        ...Object.keys(data).map(nestedKey => ({
          ...(data[nestedKey] as JsonItem),
          type: (data[nestedKey] as JsonItem).type
        }))
      ]
    } else if (typeof itemData === 'object' && key !== 'sys') {
      returnData = [...returnData, { type: key, ...unNestJson(itemData, {}) }]
    } else if (typeof itemData !== 'object') {
      returnData = [...returnData, { [key]: itemData, type: 'other' }]
    }
  })
  return returnData
}

function jsonToArray(json: JsonItem) {
  if (!json) return []
  return Object.keys(json).reduce((arr: any[], key) => {
    if (/^\d+$/.test(key)) {
      return [...arr, json[key]]
    }
    return [...arr]
  }, [])
}

type FetchDataBaseArgs = {
  id: string
  depth?: number
  unNest?: boolean
  isPreview?: boolean
}

async function fetchData({
  id,
  depth = 6,
  unNest = true,
  isPreview = false
}: FetchDataBaseArgs): Promise<JsonItem[] | JsonItem> {
  try {
    const pageRaw = await contentFulAPI.getEntry(id, depth, isPreview)
    return unNest
      ? unNestArray((pageRaw || {}).fields, [])
      : unNestJson(pageRaw.fields, {})
  } catch (e) {
    const error = e as Error
    trackError(new Error(error.message), { error, id })
    return unNest ? ([] as JsonItem[]) : ({} as JsonItem)
  }
}

async function searchDataByAttribute<T = JsonItem>(
  attribute: JsonItem,
  isPreview = false
): Promise<T | EmptyObject> {
  try {
    const pagesMatching = await contentFulAPI.getEntries(attribute, isPreview)
    const pageRaw = unNestJson(pagesMatching.items[0] || {}, {}) || {}
    return pageRaw as T
  } catch (e) {
    const error = e as Error
    trackError(new Error(error.message), { error, attribute })
    return {}
  }
}

export {
  fetchData,
  jsonToArray,
  unNestArray,
  searchDataByAttribute,
  unNestJson
}
