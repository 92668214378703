import {
  headerDesktopMenuQuery,
  headerFlashBannerQuery,
  headerMobileMenuQuery,
  headerMenuQuery
} from './queries'
import { fetchContentfulService } from '.'
import { EmptyObject } from 'types/helpers'
import {
  Menu,
  MenuDesktop,
  MenuFlashBanner,
  MenuMobile
} from 'types/contentful/graphQlApi'

export const fetchContentfulHeaderFlashBanner = () =>
  fetchContentfulService<EmptyObject, MenuFlashBanner>({
    query: headerFlashBannerQuery,
    errorMessage: 'Error fetching header data',
    section: 'Header'
  })

export const fetchContentfulHeaderDesktop = () =>
  fetchContentfulService<EmptyObject, MenuDesktop>({
    query: headerDesktopMenuQuery,
    errorMessage: 'Error fetching header data',
    section: 'Header'
  })

export const fetchContentfulHeaderMobile = () =>
  fetchContentfulService<EmptyObject, MenuMobile>({
    query: headerMobileMenuQuery,
    errorMessage: 'Error fetching header data',
    section: 'Header'
  })

export const fetchContentfulHeader = () =>
  fetchContentfulService<EmptyObject, Menu>({
    query: headerMenuQuery,
    errorMessage: 'Error fetching header data',
    section: 'Header'
  })
