import checkoutFragment from '../fragments/checkout'
import checkoutUserErrorsFragment from '../fragments/checkoutUserErrors'

const mutation = `mutation ($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
  checkoutLineItemsAdd(
    checkoutId: $checkoutId, lineItems: $lineItems
  ) {
    checkoutUserErrors ${checkoutUserErrorsFragment}
    checkout ${checkoutFragment}
  }
}
`
export default mutation
