const gbPages = {
  pages: {
    /*            */
    /* Fit Finder */
    /*            */
    'fit-finder': {
      previous: 'Previous',
      prev: 'Prev',
      next: 'Next',
      cancel: 'Cancel',
      'fine-tune': 'Fine tune your fit',
      register: 'Register',
      registration: 'Registration',
      'register-step': {
        'good-to-go': 'Good to go',
        'fit-profile-ready': 'Your fit profile is now ready to view',
        'sign-up': "Sign up and we'll send it over.",
        'email-address': 'Email address',
        'gdpr-1':
          'We use your email to update you about orders, offers & promotions. Tick here if you',
        'gdpr-2': "don't want to be added",
        'gdpr-3': ' to our email list.',
        'gdpr-4':
          'By signing up, you agree to receive our latest offers and updates. See our',
        'gdpr-5': 'privacy statement',
        'gdpr-6': 'and our',
        'gdpr-7': 'terms & conditions',
        'find-my-fit': 'Find my Fit'
      },
      'find-saved-fit': {
        'enter-email-address': 'Enter your email address',
        'find-your-saved-fit': 'We will find your previously saved fit for you',
        'no-results-found':
          'No Fit Finder Results found for this email address, please enter a different email address or retake the Fit Finder.',
        'retake-fit-finder': 'Retake the Fit Finder'
      },
      'find-saved-fit-complete': {
        'fit-coming': 'Your fit is coming',
        'sent-results': 'We sent your fit finder results to'
      },
      loading: {
        initialisation: 'Initialization',
        calculating: 'Calculating your fit..',
        'crunching-numbers': 'Crunching numbers..',
        'saving-fit': 'Saving your fit...',
        successful: 'Mission successful!',
        'try-again': 'Try again',
        redirecting: 'Redirecting',
        voila: 'Voila!'
      },
      questions: {
        'height-question': {
          'part-1': 'How',
          'part-2': 'tall',
          'part-3': 'are you',
          text: 'Slide up / down'
        },
        'weight-question': {
          'part-1': 'What is your',
          'part-2': 'weight',
          text: 'Slide left / right'
        },
        'age-question': {
          'part-1': 'How',
          'part-2': 'old',
          'part-3': 'are you',
          underline: 'We know, it has nothing to do with your fit.',
          values: {
            more: 'More'
          }
        },
        'waist-question': {
          'part-1': 'What is the most common',
          'part-2': 'waist size',
          'part-3': 'in your wardrobe today'
        },
        'size-up-question': {
          'part-1': 'Depending on the brand,',
          'part-2': 'do you ever take a',
          'part-3': 'size up',
          values: {
            never: 'Never',
            rarely: 'Rarely',
            sometimes: 'Sometimes',
            often: 'Often'
          }
        },
        'slim-fit-question': {
          'part-1': 'How do you feel about',
          'part-2': 'slim fit',
          'part-3': 'trousers',
          values: {
            often: 'I often wear slim fit trousers',
            rarely:
              'I rarely wear slim fit - usually too tight around the thigh',
            never:
              'I often struggle to fit my thighs in, even with a regular fit'
          }
        },
        'build-question': {
          'part-1': 'How would you describe',
          'part-2': 'your build',
          values: {
            slim: 'Slim Jim',
            medium: 'Average Joe',
            dadbod: 'Dadbod Dean',
            athletic: 'Shredded Pete',
            hulk: 'Hulky Harry'
          }
        },
        'watch-question': {
          'part-1': 'How do you fasten your',
          'part-2': 'wristwatch',
          values: {
            tight: 'Good and tight - I have narrow wrists',
            middle: 'Somewhere in the middle',
            loose: 'Pretty loose - I have big wrists'
          }
        },
        'tshirt-question': {
          'part-1': 'What is the most common',
          'part-2': 't-shirt size',
          'part-3': 'in your wardrobe today'
        },
        'jacket-question': {
          'part-1': 'Which',
          'part-2': 'jacket size',
          'part-3': 'fits you best today',
          values: {
            'no-idea': 'No idea'
          },
          underline:
            'To get the fit right on your chest and your shoulders alone. In British sizes.'
        },
        'style-question': {
          'part-1': 'What are you looking for',
          values: {
            'something-for-the-office': 'Something for the office',
            'something-for-the-weekend': 'Something for the weekend',
            'something-for-everyday': 'Something for everyday',
            'some-good-clean-denim': 'Some good, clean denim',
            'not-sure': 'Not sure, just browsing'
          }
        }
      },
      results: {
        title: 'Your fit results',
        byline: 'A flawless fit, every time, across our range.',
        shop: 'Shop',
        'shop-tops': 'Shop tops',
        'shop-trousers': 'Shop trousers',
        'continue-shopping': 'Continue Shopping',
        'bottom-half': 'Bottom half',
        'top-half': 'Top half',
        'top-half-waist': 'Available from 28" to 40" Including odd numbers...',
        'top-half-build':
          'Choose between A, B and C builds - narrow, regular and wide - for the perfect fit around the thigh and seat.',
        'top-half-leg':
          'Finished to order in one-inch increments from 28"-36".',
        'bottom-half-size':
          'Ranging from S to XXL. Including 2 new ‘in-between’ sizes M+ and L+.',
        'bottom-half-cut':
          'Tailored through the torso and sleeve. Available in Slim or Regular.',
        'bottom-half-length':
          'Length differs in the body and the sleeve. Choose from Short, Regular or Long.'
      },
      start: {
        title: 'Discover your perfect size in 60 seconds',
        byline:
          "We ask a few simple questions, crunch the numbers and calculate a size recommendation. It's science.",
        'your-saved-fit': 'Your saved fit:',
        begin: 'Begin',
        'fine-tune': 'Fine tune your fit',
        'have-saved-fit': 'Already have a saved fit',
        'find-it-here': 'Find it here'
      },
      'promo-banner': {
        message: 'Find your perfect fit in 60 seconds',
        link: "Let's go"
      }
    },
    /*            */
    /* Size Chart */
    /*            */
    'size-chart': {
      heading: 'How our sizing works',
      'size-and-fit': 'Size & fit',
      for: 'for',
      trouser: 'Trousers',
      trousers: 'Trousers',
      underwear: 'Underwear',
      denim: 'Denim',
      tops: 'Tops',
      shorts: 'Shorts',
      accessories: 'Accessories',
      'label-build': 'Choose Build',
      'label-cut': 'Choose Cut',
      'label-sizes': 'Choose Sizes',
      'label-waist': 'Choose Spoke Waist',
      'label-size': 'Choose Spoke Size',
      'label-product-measurements': 'Product Measurements',
      'label-international-measurements': 'International Size Guide',
      table: {
        waist: 'Waist',
        'spoke-waist': 'Spoke Waist',
        'waist-relaxed': 'Waist Relaxed',
        'waist-extended': 'Waist Extended',
        'actual-waist': 'Actual Waist',
        'front-rise': 'Front rise',
        'back-rise': 'Back rise',
        thigh: 'Thigh',
        knee: 'Knee',
        hip: 'Hip',
        chest: 'Chest',
        hem: 'Hem',
        'leg-opening': 'Leg opening',
        leg: 'Leg',
        'inside-leg': 'Inside Leg',
        'outside-leg': 'Outside Leg',
        'chest-size': 'Chest Size',
        neck: 'Neck Size',
        length: 'Length',
        'back-length-short': 'Back Length - Short',
        'back-length-regular': 'Back Length - Regular',
        'back-length-long': 'Back Length - Long',
        'sleeve-length-short': 'Sleeve Length - Short',
        'sleeve-length-regular': 'Sleeve Length - Regular',
        'sleeve-length-long': 'Sleeve Length - Long',
        slim: 'Slim',
        straight: 'Straight',
        sizes: 'Sizes'
      },
      tabs: {
        'build-a': 'A - Narrow thighs',
        'build-b': 'B - Regular thighs',
        'build-c': 'C - Wide thighs',
        slim: 'cut - slim fit',
        straight: 'cut - straight fit',
        sizes: 'Sizes'
      },
      /* How To Measure */
      htm: {
        'how-to-measure': 'How to measure',
        intro: "If you'd like to DIY, follow these steps.",
        'step-1': {
          title: 'Step 1',
          'find-a-tape': 'Find a tape',
          'which-tape':
            "We recommend a dressmaker's tape, but if you only have a builder's one that'll work too."
        },
        'step-2': {
          title: 'Step 2',
          'measure-your-favourite': 'Measure your favourite',
          'find-your-favourite':
            'Find your favourite pair of trousers or shirt, then follow these instructions.',
          'grab-a-pair':
            'By grabbing your best fitting pair from the wardrobe and measuring as follows.'
        },
        trousers: {
          'line-1':
            'All measurements describe one side of the product, laid flat.'
        },
        accessories: {
          'line-1':
            'All measurements describe the length from the belt prong to the end of the belt.'
        },
        tops: {
          'line-1':
            'All measurements describe one side of the product, laid flat.'
        },
        shorts: {
          'line-1':
            'All measurements describe one side of the product, laid flat.'
        },
        waist: {
          text: 'Waist',
          desc: 'Lay the trousers flat, and straighten out the waistband, before measuring edge to edge.',
          'subtitle-1': 'How to measure your',
          'subtitle-2': 'waist'
        },
        rise: {
          text: 'Rise',
          desc: 'Measure from the bottom of the waistband to the edge of the crotch seam.',
          'subtitle-1': 'How to measure your',
          'subtitle-2': 'rise'
        },
        thigh: {
          text: 'Thigh',
          desc: 'Lay the trousers flat, then measure across the thigh about 2cm below the crotch.',
          'subtitle-1': 'How to measure your',
          'subtitle-2': 'thigh'
        },
        knee: {
          text: 'Knee',
          desc: 'Measure 40cm down the inside leg seam of the trouser - then flatten the leg and measure across the knee at that point',
          'subtitle-1': 'How to measure your',
          'subtitle-2': 'knee'
        },
        leg: {
          text: 'Leg',
          desc: 'Lay the leg flat and measure across at the leg opening.',
          'subtitle-1': 'How to measure your',
          'subtitle-2': 'leg'
        }
      },
      byline:
        "Our size range is 3x bigger than the average brand. That's the (not so) secret to better fit. Here’s how it works:",
      'trousers-1': {
        title: '11 Waists',
        desc: 'Our anatomically curved waistband is available in 11 sizes from 28-40”, including odd numbers'
      },
      'trousers-2': {
        title: '3 Builds',
        desc: 'We cut our trousers in 3 Builds, to accommodate different shapes through the thigh and seat'
      },
      'trousers-3': {
        title: '8+ Legs',
        desc: 'We finish to order in any leg length. No more crumpled hems, or visits to the dry cleaner'
      },
      'tops-1': {
        title: '7 Chest Sizes',
        desc: "Including 2 new 'in-between' sizes"
      },
      'tops-2': {
        title: '2 Cuts',
        desc: 'Tailored through the torso & the sleeve'
      },
      'tops-3': {
        title: '3 Lengths',
        desc: 'In the body and the sleeve'
      },
      'shorts-1': {
        title: '11 Waists',
        desc: 'Our anatomically curved waistband is available in 11 sizes from 28-40”, including odd numbers'
      },
      'shorts-2': {
        title: '3 Builds',
        desc: 'We cut our trousers in 3 Builds, to accommodate different shapes through the thigh and seat'
      },
      'shorts-3': {
        title: '3 Legs',
        desc: "Whether you like to roll the hem or not, we've got a cut for you."
      }
    },
    /*          */
    /* Feedback */
    /*          */
    feedback: {
      title: 'Sixty second survey',
      byline: {
        default:
          "We are glad you love your SPOKEs - but we're on a mission to make the perfect pair, and for that we need your feedback. Fill out our short survey, and we'll send you a discount code to say thanks.",
        good: 'It’s great to hear your fit is good. If you could give us a couple more scores (it should take less than a minute), and click sumbit below, we’ll send you a discount code for any future purchase.',
        bad: 'We’re really sorry to hear we missed the mark. Any more detail would be much appreciated. This survey typically takes less than a minute to complete - and we’ll send you a discount code for any future purchase. Click here to email customer service - and find details on how to return, below.'
      },
      email: 'Your email address',
      'thank-you': 'Thank you for your feedback',
      questions: {
        'how-likely-are-you-to-recommend':
          'Overall, how likely are you to recommend SPOKE to friends or family?',
        'rate-fit': 'How would you rate the fit of your SPOKEs?',
        'improve-fit':
          'What specifically could we do to improve the fit for you?',
        'rate-quality-of-fabric':
          'How would you rate the quality of the fabric and trim in your SPOKEs?',
        'rate-quality-of-construction':
          'How would you rate the quality of construction and finishing on you SPOKEs?',
        'rate-your-online-experience':
          'How would you rate your ordering online experience?',
        'rate-delivery-and-customer-service':
          'How would you rate our delivery and customer service since you bought your SPOKEs?',
        'how-did-you-hear-about-us': 'How did you hear about us?'
      },
      answers: {
        'least-likely': 'Least likely',
        'most-likely': 'Most likely',
        'very-poor': 'Very poor',
        excellent: 'Excellent',
        sources: {
          'a-friend': 'Friend',
          'someone-i-follow': 'Someone I follow',
          'an-article-online': 'Online Article',
          'printed-article': 'Printed Article',
          'billboard-or-tube': 'A Billboard / ad on the tube',
          podcast: 'Podcast',
          'printed-ad': 'Printed Advert in a Magazine',
          instagram: 'Instagram or Facebook',
          twitter: 'Twitter',
          catalogue: 'Mailer in the Post',
          google: 'Google Search',
          youtube: 'YouTube',
          tiktok: 'Tiktok',
          tv: 'TV',
          other: 'Other'
        }
      }
    },
    /*              */
    /* Unsubscribe  */
    /*              */
    unsubscribe: {
      header: {
        title: 'Unsubscribe from our catalogue',
        copy: "We're sorry to hear that you no longer wish to receive our catalogue add yourself to our suppression list by completing the form below:"
      },
      footer: {
        'paragraph-1':
          'If you are not an existing customer of ours we will have acquired your data via one of the third party data pools we work with. These are Epsilon Abacus, Experian and Conexance. At some point you will have opted into receiving third party mailings when interacting as a customer with at least two other UK businesses.',
        'paragraph-2':
          'You can also remove yourself from the data pools by clicking the following links:',
        'paragraph-3': `Epsilon Abacus - <br /><a href="https://abacus.epsilon.com/get-in-touch/consumer-data-deletion-requests/" style="color: #475661;word-wrap: break-word;">https://abacus.epsilon.com/get-in-touch/consumer-data-deletion-requests/</a><br /><br />
        Experian - <br /><a href="https://www.experianmarketingservices.digital/OptOut" style="color: #475661;word-wrap: break-word;">https://www.experianmarketingservices.digital/OptOut</a><br /><br />
        Conexance – <br />There is no form on their website, you will need to email <a href="mailto:dpo@conexancemd.com" style="color: #475661;">dpo@conexancemd.com</a>.`,
        'paragraph-4': `If you would like to opt out for all mail of this type you can use the form here: <a href="http://www.mpsonline.org.uk/mpsr/mps_choosetype.html" style="color: #475661;word-wrap: break-word;">http://www.mpsonline.org.uk/mpsr/mps_choosetype.html</a>`,
        'paragraph-5':
          'It’s worth noting that unsubscribing can take up to six weeks to take effect due to the catalogue planning cycles.'
      },
      labels: {
        firstName: 'First Name',
        lastName: 'Last Name',
        address: 'What is the first line of your address?',
        postcode: 'Postcode',
        message:
          'Would you mind telling us why you no longer wish to receive our catalogue?'
      },
      banner: {
        'thank-you':
          'Thank you. You will stop receiving our catalogue shortly.',
        failed: 'We think you might have missed a question'
      },
      submitButton: 'Submit'
    },
    /*           */
    /*  Returns  */
    /*           */
    returns: {
      'arrange-a-return': 'Arrange a Return',
      'order-number': 'Order Number',
      'order-title': 'Order',
      postcode: 'Post Code',
      continue: 'Continue',
      'need-help': 'Need Help',
      'shop-the-store': 'Shop the store',
      shop: 'Shop',
      exchange: 'Exchange',
      'exchange-lc': 'exchange',
      'exchanges-lc': 'exchanges',
      refund: 'Refund',
      'refund-lc': 'refund',
      'refunds-lc': 'refunds',
      'fit-promise-1': 'You have until',
      'fit-promise-2': 'to find your fit for free',
      'total-items': 'Total Items',
      'items-selected': 'items selected',
      'make-an-exchange': 'Make an exchange',
      'return-reason': 'Return Reason',
      'too-tight': 'The fit is too tight',
      'too-loose': 'The fit is too loose',
      'length-not-right': 'The length isn’t right for me',
      'ordered-more-than-one': 'Ordered more than one size',
      'poor-quality': 'Poor quality / faulty',
      'confirm-and-continue': 'Confirm returns and continue',
      'select-your-size': 'Select your new size',
      other: 'Other',
      taper: 'Taper',
      done: 'Done',
      edit: 'Edit',
      cancel: 'Cancel',
      'returns-notifications': {
        'all-items-returned': 'All items in this order have been returned',
        'carrier-selection': 'Please select a carrier to complete the return',
        'return-label': {
          'copy-1': 'Your label could not be generated. Please',
          'copy-2': 'get in touch',
          'copy-3': 'to resolve this problem.'
        },
        'return-carrier': {
          'copy-1':
            'There is an issue providing carriers for this return. Please',
          'copy-2': 'get in touch',
          'copy-3': 'to resolve this problem or',
          'copy-4': 'go back',
          'copy-5': 'to your returns.'
        },
        'return-default': {
          'copy-1': 'Your return could not be issued. Please',
          'copy-2': 'get in touch',
          'copy-3': 'to resolve this problem.'
        },
        'system-down':
          'We’re seeing some issues with return orders. Please try again later.',
        'maintenance-mode': {
          'copy-1':
            'Scheduled Maintenance: Our returns portal is currently offline, but it will be back very soon!',
          'copy-2': 'Please try again later.'
        }
      },
      'return-created': 'Return Created',
      'request-a-refund': 'Request a refund',
      'select-courier': 'Select courier',
      'return-selected': 'Return Selected',
      'generate-return': 'Generate my returns',
      'all-done': 'All DONE, check your inbox',
      'finish-your-return': 'Finish your return',
      'label-instructions':
        "You'll need to print and affix your returns label to your parcel, or if your Post Office® accepts QR codes, just take your QR email along with you. Drop your parcel off at your local Post Office® branch and they'll do the rest. Remember to keep hold of your proof of postage until you've heard from us.",
      'got-it': 'Got it',
      'label-generated': 'Label generated',
      'last-step': 'Last Step',
      'view-qr-code': 'View QR code',
      'new-size-requested': 'New size requested for exchange',
      'refund-requested': 'Refund requested',
      'refund-selected': 'Refund selected',
      'return-note-modal-title': 'Is all this going in one box?',
      'return-note-modal-message':
        'If you need to return your items in multiple boxes, please select all the items coming back in one box, generate your returns label, then start the process again for the second box.',
      'qr-code-message': 'Your QR code has been sent to you via email.',
      'label-message': 'Your returns label has been sent to you via email.',
      'view-download': 'You can also view and download it here if needed.',
      'view-returns-label': 'View returns label',
      'select-returns-courier':
        'You need to select you returns courier so we can send you your returns label or QR code.'
    },
    /*                   */
    /* Thank your friend */
    /*                   */
    'thank-your-friend': {
      title: 'How claim your offer code...',
      byline:
        "First, let us know who recommended you. Then, we'll ask you for your email address. And finally we’ll send you your special offer code."
    },
    /*             */
    /* Contact Us  */
    /*             */
    contact: {
      title: 'Contact us',
      'check-faqs': 'Check out FAQs',
      'have-a-question': {
        title: 'Have a question?',
        byline: 'We have answers.'
      },
      'need-to-talk': {
        title: 'Need to talk?',
        byline: "We're here for you"
      },
      'whatsapp-us': {
        title: 'WhatsApp us'
      },
      'send-us-an-email': {
        title: 'Send us an email',
        byline: "We'll do our best to get back within a working day."
      },
      'press-enquiries': {
        title: 'Press Enquiries',
        byline: 'Thank you for your interest.'
      },
      socialise: {
        title: 'Socialise',
        byline: "We'll add Snapchat one day. When we understand what it is."
      },
      directions: {
        title: 'Location',
        byline: 'Our head office is in Richmond, London.',
        'get-directions': 'Get directions'
      }
    },
    cart: {
      title: 'Your Basket',
      'empty-basket': 'Your cart is empty',
      'empty-basket-button': 'Get Shopping',
      'free-returns': 'Free delivery & 200 day returns',
      'in-stock': 'currently in stock',
      'personalised-returns':
        'Personalised items cannot be exchanged or returned as they are made to order.',
      table: {
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price'
      },
      'gift-message': 'Gift message',
      'add-gift-message': 'Add Gift Message',
      'added-gift-message': 'Gift Message added',
      'gift-message-placeholder':
        'Dear SPOKEsmen, Enjoy your new found flawless fit',
      'gift-message-footer': 'Gift message will be inside the box when opened.',
      ymal: {
        button: 'Add to bag',
        title: 'Add {{productType}} in your size',
        loading: 'Loading products in your size...'
      }
    },
    error: {
      'report-error': 'Please report following error to us',
      'back-home': 'Back to home page',
      'page-not-found': 'Page not found',
      'sorry-page-not-found': "Sorry, we couldn't find that page."
    }
  }
}

module.exports = gbPages
