const menuCards = `
  ... on MenuProductCard {
    title
    description
    badge {
      title
      badgeType
      themeColour
      small
      iconName
     } 
    byline
    imageMobile {
      url,
      title
    }
    imageDesktop {
      url,
      title
    }
    button {
      title
      uri
      text
      classes
      buttonStyle
      event
    }
    secondaryButton {
      uri
      text
    }
    colour
    textColour
  }
`

const extendedMenuItemFields = `{
  title
  byline
  highlight: secondary
  url
  secondaryNavigation: handlesCollection(limit: 10) {
    items {
      title
      textColour
      classes
      tag
      url
      card {
        ${menuCards}
      }
      tertiaryNavigation: handlesCollection(limit: 10) {
        items {
          title
          classes
          tag
          url
          card {
            ${menuCards}
          }
        }
      }
    }
  }
  card {
    ... on MenuProductCard {
      title
    }
  }
  submenuLayout {
    type
    grid: gridItemsCollection(limit:5) {
      items {
        title
        byline
        description
        colour
        image {
          title
          url
        }
        badge {
          title
          badgeType
          themeColour
          small
          iconName
        }
        button {
          title
          text
          uri
        }
      }
    }
  }
}`

const basicMenuItemFields = `{
  title
  byline
  url
  highlight: secondary
  classes
  card {
    ${menuCards}
  }
}`

const flashBanner = `
 flashBanner {
    showBanner
    backgroundColor
    flashBannerItems: bannerItemsCollection {
      items {
        __typename
        ...on FlashBannerItemWithOffer {
          bannerName
          iconName
          iconSvg {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          messageDesktop
          messageMobile
          backgroundColor
          contentColor
          discount
          discountLabel
          linkTextDesktop
          linkUrl
        }
      }
    }
    flashBannerName
  }
`

const flashBannerNew = `
  flashBannerItem {
    ...on FlashBannerItemWithOffer {
      bannerName
      iconName
      iconSvg {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      messageDesktop
      messageMobile
      backgroundColor
      contentColor
      discount
      discountLabel
      linkTextDesktop
      linkUrl
    }
  }
`

const badgeFields = `
  {
      title
      badgeType
      themeColour
      small
      iconName
  }
`

const imageFields = `
  {
      title
      url
  }
`

export {
  badgeFields,
  basicMenuItemFields,
  extendedMenuItemFields,
  flashBanner,
  flashBannerNew,
  imageFields
}
