import { analytics } from '@helpers/analytics'
import { decode } from '@helpers/shopify'
import { getCookieByName, setCookie } from '@helpers/session'
import trackError from '@helpers/trackError'
import { facebookPixelId, facebookAdClickId } from '@helpers/products'
import { getUserEmailData } from '@helpers/session/user'
import { productEventUserProperties } from '@helpers/products'
import { ProductWithVariants } from 'types/product'
import {
  Checkout,
  CheckoutLineItemInput,
  CustomAttribute,
  DiscountApplication,
  LineItem,
  Price
} from 'types/checkout'
import { FilterOption } from 'types/filters'
import { EmptyObject } from 'types/helpers'
import { NO_SHOW_ATTRIBUTES_PROPS, ORDERED_OPTIONS } from '@consts/index'
import { getVariantItem } from '../filters'
import {
  getProductVariantAsString,
  getProductCategory,
  getProductSkuProductId,
  getProductSkuGroupId
} from '../products'
import { CartState } from '@store/redux/reducers/cartReducer'

const transformOptionsType = <T extends { type: string }>(options: T[]) =>
  options.map(option =>
    option.type === 'swimshortsleg' ? { ...option, type: 'shortsleg' } : option
  )

function getLineItemInCart(lineItems: LineItem[] = [], variantId: number) {
  const lineItem = lineItems.find(lineItem =>
    lineItem.variant.id.includes(String(variantId))
  )

  return {
    lineItemVariantId: lineItem?.variant?.id,
    lineItemId: lineItem?.id,
    quantityInCart: lineItem?.quantity || 0,
    quantityAvailable: lineItem?.variant?.quantityAvailable || 0
  }
}

export function createCartItem(
  product: ProductWithVariants,
  selectedOptions: FilterOption[],
  lineItemsInCart: LineItem[] = []
) {
  const newSelectedOptions = transformOptionsType(selectedOptions)
  const newProduct = {
    ...product,
    options: transformOptionsType(product.options)
  }
  const selectedVariants = getVariantItem(newProduct, newSelectedOptions)
  const selectedVariantsWithoutPreorder = selectedVariants.filter(
    variant => variant.variant_type !== 'partial-pre-order'
  )

  if (!selectedVariantsWithoutPreorder.length) {
    throw new Error('no variants found')
  }

  const { id: variantId, lineItemInCart } = selectedVariantsWithoutPreorder
    .map((variant, index) => {
      const isLastItem = index === selectedVariantsWithoutPreorder.length - 1
      const {
        quantityInCart,
        quantityAvailable,
        lineItemId,
        lineItemVariantId
      } = getLineItemInCart(lineItemsInCart, variant.id)

      if (
        selectedVariantsWithoutPreorder.length > 1 &&
        !isLastItem &&
        lineItemId &&
        quantityInCart >= quantityAvailable
      ) {
        return null
      }

      return {
        ...variant,
        lineItemInCart: {
          quantityInCart,
          quantityAvailable,
          lineItemId,
          lineItemVariantId
        }
      }
    })
    .filter(Boolean)[0]

  const customAttributes: CustomAttribute[] = newSelectedOptions.flatMap(
    option => {
      const key = ORDERED_OPTIONS.find(o => o.toLowerCase() === option.type)
      return key
        ? {
            key,
            value: option.value
          }
        : []
    }
  )

  return {
    lineItemInCart,
    variantId,
    customAttributes
  }
}

export function hasGiftCardItem(lineItems: LineItem[] = []): boolean {
  return !!lineItems.find(
    item => item.variant.product.productType.toLowerCase() === 'gift card'
  )
}

export function getCheckoutCookie() {
  return getCookieByName(`checkout_${process.env.SHOP_LOCALE}`)
}

export function setCheckoutCookie({ value = '', expiration = 14 } = {}) {
  setCookie(`checkout_${process.env.SHOP_LOCALE}`, value, expiration)
}

function getBasketEventProductData(product: LineItem | EmptyObject = {}) {
  const properties: Record<string, string | number> =
    !!product.customAttributes &&
    product.customAttributes.reduce(
      (result: Record<string, string | number>, attribute: CustomAttribute) => {
        if (NO_SHOW_ATTRIBUTES_PROPS.includes(attribute.key)) {
          return result
        }

        result[attribute.key.toLowerCase()] =
          Number(attribute.value) || attribute.value
        return result
      },
      {}
    )
  const optionTypes = Object.keys(properties).map(property => ({
    type: property
  }))
  const productVariant = getProductVariantAsString(properties)
  const { title, productType, url } = product.variant?.product || {}
  const {
    image,
    priceV2: price,
    compareAtPriceV2: compareAtPrice,
    sku
  } = product.variant || {}

  return {
    brand: 'SPOKE',
    product_id: getProductSkuProductId({ sku }),
    group_id: getProductSkuGroupId({ sku }),
    category: getProductCategory(optionTypes, productType),
    price: parseFloat(price?.amount) || null,
    ...(parseFloat(compareAtPrice?.amount)
      ? { was_price: parseFloat(compareAtPrice?.amount) }
      : null),
    currency: price?.currencyCode,
    color: title,
    image_url: image?.originalSrc,
    quantity: product?.quantity,
    name: productType,
    sku,
    variant: productVariant,
    url,
    ...properties
  }
}

export function cartViewedEvent(checkout: Checkout | CartState) {
  const { lineItems = [], id, totalPriceV2 } = checkout
  const eventProducts = lineItems.map(item => getBasketEventProductData(item))

  analytics('Cart Viewed', {
    cart_id: id,
    value: Number(totalPriceV2?.amount),
    currency: totalPriceV2?.currencyCode,
    products: eventProducts
  })
}

function getIsQuantityIncreased(
  product: CheckoutLineItemInput | EmptyObject = {},
  previousItems: LineItem[] = []
): boolean {
  return (
    Number(previousItems.find(item => item.id === product.id)?.quantity) <
    product.quantity
  )
}

function getIsQuantityDecreased(
  product: CheckoutLineItemInput | EmptyObject = {},
  previousItems: LineItem[] = []
): boolean {
  return (
    Number(previousItems.find(item => item.id === product.id)?.quantity) >
    product.quantity
  )
}

function getCheckoutTotalDiscountCodeValue(
  discountApplications: DiscountApplication[],
  lineItemsSubtotalPrice: Price,
  totalPrice: Price
) {
  const totalDiscountAmountValues: number[] = []
  discountApplications?.forEach(discount => {
    if (discount?.code && Object.keys(discount).length > 0) {
      // Check if discount is percentage based
      if (discount.value?.percentage) {
        totalDiscountAmountValues.push(
          Number(lineItemsSubtotalPrice?.amount) - Number(totalPrice?.amount)
        )
      }
      // Check if discount has fixed amount
      if (discount?.value?.amount) {
        totalDiscountAmountValues.push(Number(discount?.value?.amount))
      }
    }
  })
  const sumAllDiscountAmountValues = totalDiscountAmountValues.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue
    },
    0
  )
  return sumAllDiscountAmountValues
}

function getDiscountCode(discountApplications: DiscountApplication[]) {
  if (!discountApplications) {
    return null
  }

  const filteredDiscountsByCode = discountApplications.filter(discount => {
    if (Object.keys(discount).length > 0 && discount?.code) {
      return discount
    }
  })

  return filteredDiscountsByCode.length > 0
    ? filteredDiscountsByCode[0].code
    : null
}

export function basketEvents({
  checkout = {},
  previousItems = [],
  context = '',
  product = {}
}: {
  checkout: Checkout | EmptyObject
  previousItems: LineItem[]
  context: string
  product: CheckoutLineItemInput | EmptyObject
}) {
  try {
    const {
      lineItems = [],
      id,
      webUrl,
      lineItemsSubtotalPrice,
      totalPriceV2: totalPrice,
      email: checkoutEmail,
      discountApplications
    } = checkout
    const customerEmail = checkoutEmail || getUserEmailData()

    const checkoutDiscountCodeTotalValue = getCheckoutTotalDiscountCodeValue(
      discountApplications,
      lineItemsSubtotalPrice,
      totalPrice
    )
    const checkoutDiscountCode = getDiscountCode(discountApplications)

    const props = {
      ...(customerEmail && { email: customerEmail }),
      ...(checkoutDiscountCode && { coupon: checkoutDiscountCode }),
      ...(checkoutDiscountCodeTotalValue > 0 && {
        discount: checkoutDiscountCodeTotalValue.toFixed(1)
      }),
      context,
      cart_id: id,
      checkout_url: webUrl,
      total: Number(totalPrice?.amount)
    }
    const eventProducts = lineItems.map(item => getBasketEventProductData(item))

    if (!id) {
      return false
    }

    if (lineItems.length >= 1 && previousItems.length === 0) {
      const eventProps = {
        ...props,
        products: eventProducts
      }
      analytics('Basket Created', eventProps)
    }

    if (
      lineItems.length > previousItems.length ||
      getIsQuantityIncreased(product, previousItems)
    ) {
      const eventProduct = lineItems.find(
        item =>
          decode(item?.variant.id).id === decode(product?.variantId || '').id
      )
      const productEventData = getBasketEventProductData(eventProduct)
      const eventProps = {
        ...props,
        ...productEventData,
        ...(!!facebookPixelId && { fbp: facebookPixelId }),
        ...(!!facebookAdClickId && { fbc: facebookAdClickId }),
        ...productEventUserProperties(),
        quantity: 1
      }
      analytics('Product Added', eventProps)
    }

    if (
      lineItems.length < previousItems.length ||
      getIsQuantityDecreased(product, previousItems)
    ) {
      const eventProduct = previousItems.find(
        item => item?.variant.id === product.variantId
      )
      const productEventData = getBasketEventProductData(eventProduct)
      const eventProps = {
        ...props,
        ...productEventData,
        ...(lineItems.length === previousItems.length && { quantity: 1 })
      }
      analytics('Product Removed', eventProps)
    }

    if (lineItems.length === 0 && previousItems.length > 0) {
      analytics('Basket Emptied', props)
    } else {
      analytics('Basket Updated', {
        ...props,
        products: eventProducts
      })
    }
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'basketEvents failed'
    const error = new Error(errorMessage)
    trackError(error, { error })
  }
}

export function getAbTestCheckoutAttributes(
  runningAbTests: Record<string, string>
) {
  if (!runningAbTests) {
    return []
  }

  return Object.keys(runningAbTests).map(testKey => {
    return {
      key: `exp-${testKey}`,
      value: `exp-${testKey}-${runningAbTests[testKey]}`
    }
  })
}
