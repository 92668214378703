import { createContext } from 'react'
import { FlashBanner } from '@pages/_app'
import { HeaderState } from '@store/redux/reducers/otherReducers'
import { EmptyObject } from 'types/helpers'
import { HeaderProps, HeaderTheme, MenuLinks } from 'types/header'

type AppContextProps = {
  flashBanner: FlashBanner
  desktop: HeaderState['desktop']
  mobile: MenuLinks | EmptyObject
  header: HeaderProps
  headerTheme: HeaderTheme
  setHeaderTheme: (arg: HeaderTheme) => void
}

const AppContext = createContext<AppContextProps>({
  flashBanner: {},
  header: {},
  desktop: {},
  mobile: {},
  headerTheme: 'default',
  setHeaderTheme: () => undefined
})

export default AppContext
