import {
  NotificationType,
  PushNotificationBannerProps
} from '@components/FlashBanner'
import { EmptyObject } from 'types/helpers'

export type HeaderState = {
  mobile: Record<string, unknown> | EmptyObject
  desktop: Record<string, unknown> | EmptyObject
}
export type HeaderAction = {
  payload: HeaderState
} & (
  | {
      type: 'HEADER_FETCHED'
    }
  | {
      type: 'RESET_DYNAMIC_FILTERS'
    }
)
const defaultHeaderState = {
  mobile: {},
  desktop: {}
}

export type CartOpenState = boolean
export type CartOpenAction = {
  type: 'OPEN_CART' | 'CLOSE_CART'
}

export type NotificationBannerState = {
  isVisible: boolean
  type: NotificationType
  theme: PushNotificationBannerProps['theme']
}
export type NotificationBannerAction = {
  type: 'HIDE_SHOW_NOTIFICATION_BANNER'
  payload: {
    isVisible: boolean
    type?: NotificationType
    theme?: NotificationBannerState['theme']
  }
}

type FilterByCategoryState = string
export type FilterByCategoryAction = {
  type: 'CATEGORY_FILTER'
  payload: string
}

function header(
  state: HeaderState = defaultHeaderState,
  { type, payload }: HeaderAction
): HeaderState {
  switch (type) {
    case 'HEADER_FETCHED':
      return { ...state, ...payload }

    default:
      return state
  }
}

function isCartOpen(
  state: CartOpenState = false,
  { type }: CartOpenAction
): CartOpenState {
  switch (type) {
    case 'OPEN_CART':
      return true

    case 'CLOSE_CART':
      return false

    default:
      return state
  }
}

function notificationBanner(
  state: NotificationBannerState = {
    isVisible: false,
    type: undefined,
    theme: 'default'
  },
  { type, payload }: NotificationBannerAction
): NotificationBannerState {
  if (type === 'HIDE_SHOW_NOTIFICATION_BANNER') {
    return { ...state, ...payload }
  }
  return state
}

function filterByCategory(
  state: FilterByCategoryState = 'All',
  { type, payload }: FilterByCategoryAction
): FilterByCategoryState {
  if (type === 'CATEGORY_FILTER') {
    return payload
  }
  return state
}

const reducers = {
  header,
  isCartOpen,
  notificationBanner,
  filterByCategory
}

export default reducers
