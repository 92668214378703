/* eslint-disable import/prefer-default-export */
import { checkoutUpdate } from '@api/graphqlShopify'
import { arrayPartition } from '@helpers/utils'
import { getCheckoutUpdatePayload } from '@components/cart/helpers'

export async function purgeNullVariantsFromCheckout(lineItems) {
  const invalidLineItems = arrayPartition(lineItems, lineItem => {
    return !!lineItem.variant
  })[1]

  if (invalidLineItems.length > 0) {
    const removeLineItems = invalidLineItems.map(lineItem => {
      const { payload } = getCheckoutUpdatePayload({
        id: lineItem.id,
        quantity: 0,
        lineItems
      })
      return checkoutUpdate(payload)
    })

    const updateResponse = await Promise.all(removeLineItems)
    const lastResponse = updateResponse[updateResponse.length - 1]
    return lastResponse
  }

  return null
}
