import React, { useEffect, useState, ReactNode } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { fetchHeaderMenuAction } from '@store/redux/actions/headerActions'
import { analytics } from '@helpers/analytics'
import { getCookieByName, setCookie } from '@helpers/session'
import { isBrowser, getHelpdeskLink } from '@helpers/env'
import Translations from '@helpers/translations'
import { COOKIE_EXPIRE_6_MONTHS } from '@consts/index'
import PageError from '@components/PageError'
import ErrorBoundary from '@components/ErrorBoundary'
import Footer from '../Footer/index'
import CountrySwitcher from '../CountrySwitcher'
import LayoutModals from './LayoutModals'
import styles from './styles.module.scss'
import NewHeader from '@components/NewHeader'

const CookieBanner = dynamic(() => import('../CookieBanner'), { ssr: false })
const ZenDesk = dynamic(() => import('../ZenDesk'), { ssr: false })

const Layout = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { pathname, query } = router

  const [checkGDPRConsent, setCheckGDPRConsent] = useState<boolean>(
    getCookieByName('th_pp_consent') === 'true'
  )

  useEffect(() => {
    if (query.preview) {
      // dispatch(fetchHeaderMenuAction()) // get header client-side
    }
  }, [dispatch, query.preview])

  useEffect(() => {
    setCookie('th_pp_consent', 'true', COOKIE_EXPIRE_6_MONTHS)
  }, [])

  const setGDPRConsent = () => {
    analytics('Cookie banner - consent given', {
      label: 'Cookie banner',
      source: 'Cookie banner'
    })
    setCheckGDPRConsent(true)
  }

  const contentWrapperClasses = classNames(styles.wrapper__content, {
    [styles.wrapper__content_minHeight]: /^\/cart$/.test(pathname)
  })

  return (
    <div className={styles.wrapper}>
      <NewHeader />

      <ErrorBoundary
        fallback={
          <PageError title="Oops, something's gone wrong.">
            <p>{Translations.localise('common.layout.error.line1')}</p>
            <p>
              {Translations.localise('common.layout.error.line2')}{' '}
              <a
                href={getHelpdeskLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQs
              </a>{' '}
              {Translations.localise('common.layout.error.line3')}
            </p>
          </PageError>
        }
      >
        <div className={contentWrapperClasses}>{children}</div>
      </ErrorBoundary>
      <div className={styles.wrapper__footer}>
        <LayoutModals />
        <CountrySwitcher />
        <Footer />
      </div>
      {isBrowser() && !checkGDPRConsent && (
        <CookieBanner setGDPRConsent={setGDPRConsent} />
      )}
      <ZenDesk cookieBannerOpen={!checkGDPRConsent} />
    </div>
  )
}

export default Layout
