import { LineItem } from 'types/checkout'

/* eslint-disable import/prefer-default-export */
const PROPERTY_NAMES = {
  shortsleg: 'Leg',
  taper: 'Lower Leg',
  message: ''
} as Record<string, string>

export function getCartItemProductOption(
  property: string,
  value: string
): string | null {
  if (!property || !value) return null

  const propertyShortName = PROPERTY_NAMES[property.toLowerCase()] || property
  return `${propertyShortName}: ${value}`
}

type CheckoutUpdatePayloadArgs = {
  id: string
  variantId: string
  quantity: number
  lineItems: LineItem[]
}

export function getCheckoutUpdatePayload({
  id,
  variantId,
  quantity,
  lineItems
}: CheckoutUpdatePayloadArgs) {
  return { payload: [{ id, quantity, variantId }], newItems: lineItems }
}
