import checkoutFragment from '../fragments/checkout'
import checkoutUserErrorsFragment from '../fragments/checkoutUserErrors'

const mutation = `mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
  checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
    checkout ${checkoutFragment}
    checkoutUserErrors ${checkoutUserErrorsFragment}
  }
}`

export default mutation
