import { ButtonLink } from '@components/ui/Button'
import { OPEN_FIT_FINDER } from '@consts/index'
import { getUserSavedFitData } from '@helpers/session/user'
import CartMenuIcon from '@components/NewHeader/shared/CartMenuIcon'
import { ReactComponent as AccountIcon } from '@svgs/menu-account.svg'
import styles from './styles.module.scss'
import { CartEvent } from '@components/NewHeader'
import { getLink } from '@helpers/env'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/NewHeader/consts'

type SecondaryNavLinksProps = {
  onCartOpen: (e: CartEvent['event']) => void
}

export default function SecondaryNavLinks({
  onCartOpen
}: SecondaryNavLinksProps) {
  const userHasSavedFitCookies = getUserSavedFitData()

  return (
    <div className={styles.DesktopHeader__secondaryLinks}>
      <ButtonLink
        href={`#${OPEN_FIT_FINDER}`}
        rawHref={true}
        data-testid="fitfinder-desktop-nav-button"
        variant="secondary"
        size="small"
        classes={styles.fitFinderMenuLink}
        onClick={() =>
          analytics('Click', {
            category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
            label: 'My Fit'
          })
        }
      >
        {userHasSavedFitCookies ? 'My Fit' : 'Find your Fit'}
      </ButtonLink>
      <a
        className={styles.DesktopHeader__accountIcon}
        data-testid="account-desktop-nav-button"
        href={getLink('/account')}
        onClick={() => {
          analytics('Click', {
            category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
            label: 'My Account'
          })
        }}
      >
        <AccountIcon width={23} height={23} />
      </a>
      <button className={styles.DesktopHeader__cartIcon} onClick={onCartOpen}>
        <CartMenuIcon />
      </button>
    </div>
  )
}
