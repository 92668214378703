import { useEffect, useState } from 'react'
import HeaderLogo from '../../shared/HeaderLogo'
import { ReactComponent as AccountIcon } from '@svgs/menu-account.svg'
import { getLink } from '@helpers/env'
import CartMenuIcon from '@components/NewHeader/shared/CartMenuIcon'
import { CartEvent } from '@components/NewHeader'
import MobileMenuDrawerOverlay from '@components/NewHeader/HeaderWrapper/mobile/MobileMenuDrawerOverlay'
import styles from './styles.module.scss'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/NewHeader/consts'
import { getCookieByName } from '@helpers/session'
import { useDispatch } from 'react-redux'
import { flyoutMenuTrackerAction } from '@store/redux/actions/popUpsTrackerActions'

export default function MobileHeader({
  onCartOpen,
  isFitFinderOpen
}: {
  onCartOpen: (e: CartEvent['event']) => void
  isFitFinderOpen: boolean
}) {
  const dispatch = useDispatch()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const handleBurgerClick = () => {
    setIsMobileMenuOpen(true)
    analytics('Click', {
      category: 'Menu Click'
    })
  }
  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false)
  }

  useEffect(() => {
    if (isFitFinderOpen && isMobileMenuOpen) {
      setIsMobileMenuOpen(false)
    }
  }, [isFitFinderOpen, isMobileMenuOpen])

  useEffect(() => {
    // Updates the Newsletter modal popup store status
    if (!getCookieByName('hideNewsletterPrompt')) {
      dispatch(flyoutMenuTrackerAction({ flyoutMenu: isMobileMenuOpen }))
    }
  }, [dispatch, isMobileMenuOpen])

  return (
    <>
      <div className={styles.MobileHeader}>
        <HeaderLogo
          width="44"
          height="37"
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'Logo'
            })
          }}
        />

        <a
          data-testid="account-mobile-nav-button"
          className={styles.MobileHeader__accountIcon}
          href={`${getLink('/account')}`}
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'My Account'
            })
          }}
        >
          <AccountIcon />
        </a>

        <button
          aria-label="cart"
          onClick={onCartOpen}
          type="button"
          className={styles.MobileHeader__cartButton}
        >
          <CartMenuIcon />
        </button>

        <button
          className={styles.MobileHeader__toggleMenu}
          onClick={handleBurgerClick}
          type="button"
          aria-label="main navigation"
        >
          <span></span>
        </button>
      </div>
      <MobileMenuDrawerOverlay
        isMobileMenuOpen={isMobileMenuOpen}
        handleCloseMobileMenu={handleCloseMobileMenu}
      />
    </>
  )
}
